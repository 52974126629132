import { MapElementFactory } from "vue2-google-maps";

export default MapElementFactory({
  name: "directionsRenderer",

  ctr() {
    return window.google.maps.DirectionsRenderer;
  },

  events: [],

  mappedProps: {},

  props: {
    origin: { type: Object },
    destination: { type: Object },
    travelMode: { type: String },
    miles: { type: String },
    waypoints: { type: Array }
  },

  afterCreate(directionsRenderer) {
    let directionsService = new window.google.maps.DirectionsService();

    this.$watch(
      () => [this.origin, this.destination, this.travelMode, this.waypoints],
      () => {
        let { origin, destination, travelMode, waypoints } = this;
        if (!origin || !destination || !travelMode) return;
        // console.log('waypoints directions render');
        // console.log(waypoints);
        // let routeModifiers = {
        //   "vehicle_info":{
        //     "emission_type": "GASOLINE"
        //   },
        //   "toll_passes": "US_WA_GOOD_TO_GO"
        // }
        directionsService.route(
          {
            origin,
            destination,
            travelMode,
            waypoints,
            
          },
          (response, status) => {
            if (status !== "OK") return;
            
            let totalMiles = 0;
            //this.miles = response.routes[0].legs[0].distance.text ;
            console.log(response.routes[0]);
            for (let i = 0; i < response.routes[0].legs.length; i++) {
              let miles = response.routes[0].legs[i].distance.text.split(" mi")[0].replace(',','');
              let legsAddition = parseFloat(miles);
              
              totalMiles += legsAddition ;
            
            }
            this.$emit("updated-miles", totalMiles);
            this.$emit("overview-path", response.routes[0].overview_path);
            //this.miles = response.routes[0].legs[0].distance.text ;
            //this.$emit("updated-miles", response.routes[0].legs[0].distance.text);
            directionsRenderer.setDirections(response);
          }
        );
      }
    );
  }
});
